import React, { useContext, useState, useEffect } from "react";
import LandingPageHeader from "../../components/Header";
import FormError from "../../components/FormError";
import { AuthContext } from "../../context/AuthContext";
import FormSuccess from "../../components/FormSuccess";
import { Redirect } from "react-router-dom";

const Login = () => {
  const authContext = useContext(AuthContext);
  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [failure, setFailure] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [redirectOnLogin, setRedirectOnLogin] = useState(false);

  const handleClick = () => {
    setSubmitting(true);
  };

  useEffect(() => {
    const url = new URL(window.location.href).search;
    const status = new URLSearchParams(url).get("status");

    if (status === null) {
      return;
    } else if (status.includes("200")) {
      const firstName = new URLSearchParams(url).get("firstName");
      const lastName = new URLSearchParams(url).get("lastName");
      const email = new URLSearchParams(url).get("email");
      const msg = new URLSearchParams(url).get("message");
      const token = new URLSearchParams(url).get("token");
      const full_role = new URLSearchParams(url).get("role");
      const role = full_role.split(".")[1];
      const expiryDate = new URLSearchParams(url).get("expiryDate");

      const data = {
        firstName,
        lastName,
        email,
        role,
        token,
        expiryDate,
      };
      setMessage(msg);
      setSuccess(true);
      authContext.setAuthState(data);
      setTimeout(() => {
        setRedirectOnLogin(true);
      }, 700);
    } else if (status.includes("400")) {
      const errorMsg = new URLSearchParams(url).get("error");
      setFailure(true);
      setErrorMessage(errorMsg);
      setTimeout(() => {
        setFailure(false);
        setErrorMessage("");
      }, 3500);
    }
    //eslint-disable-next-line
  }, []);

  return (
    <>
      {redirectOnLogin && <Redirect to="/admin/dashboard" />}
      <LandingPageHeader content="Admin Portal" />
      <div className="flex items-center mt-12">
        <div className="w-full bg-white p-8 m-4 md:max-w-sm md:mx-auto">
          <h1 className="block w-full text-center text-2xl mb-6 font-serif">
            Log in to your account
          </h1>
          {failure && <FormError text={errorMessage} />}
          {success && <FormSuccess text={message} />}
          <a
            href={`${process.env.REACT_APP_PROD_BASE_URL}/oauth2/authorization/azure-client`}
            className="block bg-green-500 hover:bg-teal-dark text-white text-base p-3 mt-5 rounded font-serif md:w-full"
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
            onClick={handleClick}
          >
            {isSubmitting ? (
              <div className="loading-spinner"></div>
            ) : (
              <span>Continue With Decagon Account</span>
            )}
          </a>
        </div>
      </div>
    </>
  );
};

export default Login;
