import React from "react";
import decagonlight from "../../../NewAssets/decagonlight.svg";
import styled from "styled-components";

const Congrats = () => {
  return (
    <>
      <CongratsStyle>
        <img src={decagonlight} className="decagonlight" alt="decagon-logo" />
        <h1 className="congrats done">
          Congratulations! <br />
          You have successfully applied for the Training
        </h1>
        <div className="check done">
          Kindly check your email inbox or spam regularly as we would be sending
          you feedback on the status of your application and an email inviting
          you to join circle (The engagement platform for the recruitment
          process).
        </div>
        <a href="https://decagon.institute/" className="gohome done">
          Go Home
        </a>
      </CongratsStyle>
    </>
  );
};

const CongratsStyle = styled.div`
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  background: #2d2f48;

  .done {
    text-align: center;
    color: #ffffff;
  }

  .decagonlight {
    padding: 1rem;
  }
  .congrats {
    font-weight: 800;
    font-size: 3rem;
    line-height: 69px;
    max-width: 50%;
    padding-top: 4rem;
    padding-bottom: 2rem;
  }
  .verify,
  .check {
    font-size: 1rem;
    line-height: 40px;
    max-width: 45%;
    padding: 1rem 0;
  }
  .gohome {
    margin: 1rem 0;
    border: 1px solid #ffffff;
    padding: 1rem 3rem !important;
  }
  @media only screen and (max-width: 768px) {
    height: 100%;
    .congrats {
      font-weight: 800;
      line-height: 45px;
      font-size: 1.5rem;
      max-width: 80%;
      padding: 1rem 0;
    }
    .verify,
    .check {
      font-size: 1rem;
      max-width: 80%;
    }
    .done {
      padding: 0.5rem 0;
    }
  }
`;

export default Congrats;
