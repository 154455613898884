import React from "react";
import { Redirect, Route } from "react-router-dom";

const PrivateRoute = ({ children, isAuth, notAuth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => (isAuth ? [children] : <Redirect to={notAuth} />)}
    />
  );
};

/**
 *
 * @param children
 * @param {boolean} isAuth
 * @param ifAuth
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
export const ProtectedRoute = ({ children, isAuth, ifAuth, ...rest }) => {
  return (
    <Route
      {...rest}
      render={() => (!isAuth ? [children] : <Redirect to={ifAuth} />)}
    />
  );
};

export default PrivateRoute;
