import React from "react";
import {Link} from "react-router-dom";
import StyleLogin from "./SignUp/styles";
import Header from "../../components/Header";
import Footer from "./SignUp/Footer";
const Check = () => {
  return (
    <>
      <Header />
      <StyleLogin>
        <h1 className="login-title">Check your email</h1>
        <p
          style={{ width: "35%", textAlign: "center", margin: "1rem 0" }}
          className="forgot-text"
        >
          We have sent a secure password reset link to your email address.
          Kindly click on the link to reset your password. Cheers!
        </p>
        <div style={{ width: "30%" }} id="check">
          <Link to="/login"
            style={{ width: "100%", margin: "2rem 0" }}
            className="btn login-btn"
          >
            Back to home
          </Link>
        </div>

        <Footer />
      </StyleLogin>
    </>
  );
};

export default Check;
