import React, { useRef, useState } from "react";
import styled from "styled-components";
import { format, isValid, parse } from "date-fns";
import FocusTrap from "focus-trap-react";
import { DayPicker } from "react-day-picker";
import { usePopper } from "react-popper";

export default function DatePickerDialog({
  //   label,
  required,
  name,
  setDateValue,
  dateValue,
  setFieldValue,
}) {
  const [selected, setSelected] = useState();
  const [isPopperOpen, setIsPopperOpen] = useState(false);

  const popperRef = useRef(null);
  const buttonRef = useRef(null);
  const [popperElement, setPopperElement] = useState(null);

  const popper = usePopper(popperRef.current, popperElement, {
    placement: "bottom-start",
  });

  const closePopper = () => {
    setIsPopperOpen(false);
    buttonRef?.current?.focus();
  };

  const handleInputChange = (e) => {
    setDateValue(e.currentTarget.value);
    setFieldValue("dateOfBirth", e.currentTarget.value);
    const date = parse(e.currentTarget.value, "y-MM-dd", new Date());
    if (isValid(date)) {
      setSelected(date);
    } else {
      setSelected(undefined);
    }
  };

  const handleButtonClick = () => {
    setIsPopperOpen(true);
  };

  const handleDaySelect = (date) => {
    setSelected(date);
    if (date) {
      setDateValue(format(date, "y-MM-dd"));
      setFieldValue("dateOfBirth", format(date, "y-MM-dd"));
      closePopper();
    } else {
      setDateValue("");
      setFieldValue("dateOfBirth", "");
    }
  };

  return (
    <div className="">
      <StyledFormInputComponent ref={popperRef}>
        {/* {label && (
          <label htmlFor={name} className="form-font form-label">
            {label}
            {required && <span style={{ color: "red" }}> *</span>}
          </label>
        )} */}
        <input
          disabled
          type="text"
          placeholder={format(new Date("2000-12-10"), "y-MM-dd")}
          value={dateValue}
          onChange={handleInputChange}
          className="input-reset pa2 ma2 bg-white black ba"
        />
        <button
          ref={buttonRef}
          type="button"
          className="pa2 bg-white button-reset ba"
          aria-label="Pick a date"
          onClick={handleButtonClick}
        >
          <span role="img" aria-label="calendar icon">
            📅
          </span>
        </button>
      </StyledFormInputComponent>
      {isPopperOpen && (
        <FocusTrap
          active
          focusTrapOptions={{
            initialFocus: false,
            allowOutsideClick: true,
            clickOutsideDeactivates: true,
            onDeactivate: closePopper,
            fallbackFocus: buttonRef.current,
          }}
        >
          <div
            tabIndex={-1}
            style={popper.styles.popper}
            className="bg-white border border-black z-50 rdpWrapper"
            {...popper.attributes.popper}
            ref={setPopperElement}
            role="dialog"
          >
            <DayPicker
              initialFocus={isPopperOpen}
              mode="single"
              defaultMonth={selected}
              fromYear={1930}
              toYear={2010}
              captionLayout="dropdown"
              selected={selected}
              onSelect={handleDaySelect}
            />
          </div>
        </FocusTrap>
      )}
    </div>
  );
}

const StyledFormInputComponent = styled.div`
  /* background: #fff; */
  display: flex;
  .dialog-sheet {
    background-color: #fff;
    border: 1px solid #000;
    z-index: 99;
  }
`;
