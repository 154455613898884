import React from "react";
import { Form, Formik, Field, ErrorMessage, useField } from "formik";
import * as Yup from "yup";
// import moment from "moment";
import { experience, info, paymentOption, resumptionDate } from "./data";

const Education = (props) => {
  const validationSchema2 = Yup.object().shape({
    programmingExperience: Yup.string()
      .required("Select an option.")
      .nullable(),
    productExperience: Yup.string().required("Select an option.").nullable(),
    descriptionOfProjectContribution: Yup.string().when("productExperience", {
      is: "no",
      then: (schema) => schema,
      otherwise: Yup.string().when("productExperience", {
        is: "yes",
        then: Yup.string().required("Please share your experience.").nullable(),
      }),
    }),
    outstandingAchievement: Yup.string()
      .required("Please share your experience.")
      .nullable(),
    leadershipPositions: Yup.string().required("Select an option").nullable(),
    descriptionOfLeadershipPosition: Yup.string().when("leadershipPositions", {
      is: "no",
      then: (schema) => schema,
      otherwise: Yup.string().when("leadershipPositions", {
        is: "yes",
        then: Yup.string().required("Please share your experience.").nullable(),
      }),
    }),
    paymentOption: Yup.string()
      .required("Payment Option is required.")
      .nullable(),
    linkedIn: Yup.string().notRequired().nullable(true),
    hearAboutUs: Yup.string()
      .required("Where did you hear about us section is required.")
      .nullable(true),
    preferredResumptionDate: Yup.string()
      .required("Preferred resumption date is required.")
      .nullable(true),
  });

  const handleSubmit = (values) => {
    props.next(values, true);
  };

  return (
    <>
      <div className="content">
        <h1 className="tell-us font-bold">Programming Experience</h1>
        <p className="better">We would like some more information.</p>
        <Formik
          initialValues={props.data}
          onSubmit={handleSubmit}
          validationSchema={validationSchema2}
          enableReinitialize
        >
          {({ setFieldValue, values }) => {
            return (
              <Form className="personal-info-form">
                <div className="wrapper">
                  <div className="label">
                    <p>Programming Experience</p>
                  </div>
                  <Field
                    as="select"
                    name="programmingExperience"
                    style={{ width: "90%" }}
                  >
                    <option>Select option</option>
                    {experience.map((experience) => (
                      <option value={experience.value} key={experience.value}>
                        {experience.label}
                      </option>
                    ))}
                  </Field>
                  <i className="arrow_down fas fa-chevron-down"></i>
                  <div className="single-error-div">
                    <ErrorMessage
                      name="programmingExperience"
                      render={(msg) => (
                        <p
                          style={{ color: "red", fontSize: "12px" }}
                          className="text-danger font-sm error1 font-weight-bold"
                        >
                          {msg}
                        </p>
                      )}
                    />
                  </div>
                </div>

                {values.programmmingExperience === "beginner" ||
                "intermediate" ? (
                  <>
                    <div className="wrapper">
                      <div className="label">
                        <p style={{ position: "relative", top: ".5rem" }}>
                          Have you contributed to any project using your
                          programming experience?
                        </p>
                      </div>
                      <Field
                        as="select"
                        name="productExperience"
                        style={{ width: "90%" }}
                      >
                        <option>Select option</option>
                        <option value={"yes"}>Yes</option>
                        <option value={"no"}>No</option>
                      </Field>
                      <i className="arrow_down fas fa-chevron-down"></i>
                      <div className="single-error-div">
                        <ErrorMessage
                          name="productExperience"
                          render={(msg) => (
                            <p
                              style={{ color: "red", fontSize: "12px" }}
                              className="text-danger font-sm error1 font-weight-bold"
                            >
                              {msg}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                  </>
                ) : null}

                {values.productExperience === "yes" && (
                  <div className="relative">
                    <MyTextArea
                      label="Briefly describe your programming contribution to the product/project"
                      name="descriptionOfProjectContribution"
                      rows="2"
                      placeholder="Type here..."
                    />
                    <div
                      style={{ position: "absolute", left: "1rem", bottom: 0 }}
                      className="single-error-div"
                    >
                      <ErrorMessage
                        name="descriptionOfProjectContribution"
                        render={(msg) => (
                          <p
                            style={{ color: "red", fontSize: "12px" }}
                            className="text-danger font-sm error1 font-weight-bold"
                          >
                            {msg}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                )}

                <h1 className="tell-us my-3 font-bold">
                  Achievements & Leadership
                </h1>

                <div className="relative">
                  <MyTextArea
                    label="What do you consider as your most outstanding achievement(s)?"
                    name="outstandingAchievement"
                    rows="2"
                    placeholder="At school, work, business, any area at all. "
                  />
                  <div
                    style={{ position: "absolute", left: "1rem", bottom: 0 }}
                    className="single-error-div"
                  >
                    <ErrorMessage
                      name="outstandingAchievement"
                      render={(msg) => (
                        <p
                          style={{ color: "red", fontSize: "12px" }}
                          className="text-danger font-sm error1 font-weight-bold"
                        >
                          {msg}
                        </p>
                      )}
                    />
                  </div>
                </div>

                <div className="wrapper">
                  <div className="label">
                    <p style={{ position: "relative", top: ".5rem" }}>
                      Have you held any leadership positions in the past?
                    </p>
                  </div>
                  <Field
                    as="select"
                    name="leadershipPositions"
                    style={{ width: "95%" }}
                  >
                    <option>Select option</option>
                    <option value={"yes"}>Yes</option>
                    <option value={"no"}>No</option>
                  </Field>
                  <i className="arrow_down fas fa-chevron-down"></i>
                  <div className="single-error-div">
                    <ErrorMessage
                      name="leadershipPositions"
                      render={(msg) => (
                        <p
                          style={{ color: "red", fontSize: "12px" }}
                          className="text-danger font-sm error1 font-weight-bold"
                        >
                          {msg}
                        </p>
                      )}
                    />
                  </div>
                </div>
                {values.leadershipPositions === "yes" && (
                  <div className="relative">
                    <MyTextArea
                      label="If Yes, Describe the leadership position(s) and how you played it/them"
                      name="descriptionOfLeadershipPosition"
                      rows="2"
                      placeholder="Type here...."
                    />
                    <div
                      style={{ position: "absolute", left: "1rem", bottom: 0 }}
                      className="single-error-div"
                    >
                      <ErrorMessage
                        name="descriptionOfLeadershipPosition"
                        render={(msg) => (
                          <p
                            style={{ color: "red", fontSize: "12px" }}
                            className="text-danger font-sm error1 font-weight-bold"
                          >
                            {msg}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                )}

                <h1 className="tell-us my-3 font-bold">Others</h1>
                <div className="wrapper">
                  <div className="label">
                    <p>Preferred Payment Option</p>
                  </div>
                  <Field
                    as="select"
                    name="paymentOption"
                    style={{ width: "95%" }}
                  >
                    <option>Select option</option>
                    {paymentOption.map((paymentOption) => (
                      <option
                        value={paymentOption.value}
                        key={paymentOption.value}
                      >
                        {paymentOption.label}
                      </option>
                    ))}
                  </Field>
                  <i className="arrow_down fas fa-chevron-down"></i>
                  <div className="single-error-div">
                    <ErrorMessage
                      name="paymentOption"
                      render={(msg) => (
                        <p
                          style={{ color: "red", fontSize: "12px" }}
                          className="text-danger font-sm error1 font-weight-bold"
                        >
                          {msg}
                        </p>
                      )}
                    />
                  </div>
                </div>
                <div className="wrapper">
                  <div className="label">
                    <p>LinkedIn Profile Link (Optional)</p>
                  </div>
                  <Field
                    name="linkedIn"
                    type="text"
                    placeholder="Type your LinkedIn profile link here"
                    style={{ width: "100%" }}
                    className="input-email"
                  />
                  <div className="single-error-div">
                    <ErrorMessage
                      name="linkedIn"
                      render={(msg) => (
                        <p
                          style={{ color: "red", fontSize: "12px" }}
                          className="text-danger font-sm error1 font-weight-bold"
                        >
                          {msg}
                        </p>
                      )}
                    />
                  </div>
                </div>

                <div className="wrapper">
                  <div className="label">
                    <p>How did you hear about us?</p>
                  </div>
                  <Field
                    as="select"
                    name="hearAboutUs"
                    style={{ width: "95%" }}
                  >
                    <option>Select option</option>
                    {info.map((info) => (
                      <option value={info.value} key={info.value}>
                        {info.label}
                      </option>
                    ))}
                  </Field>
                  <i className="arrow_down fas fa-chevron-down"></i>
                  <div className="single-error-div">
                    <ErrorMessage
                      name="hearAboutUs"
                      render={(msg) => (
                        <p
                          style={{ color: "red", fontSize: "12px" }}
                          className="text-danger font-sm error1 font-weight-bold"
                        >
                          {msg}
                        </p>
                      )}
                    />
                  </div>
                </div>

                <div className="wrapper">
                  <div className="label">
                    <p>Choose Preferred Resumption Date and Location.</p>
                  </div>
                  <Field
                    as="select"
                    name="preferredResumptionDate"
                    style={{ width: "95%" }}
                  >
                    <option>Select option</option>
                    {resumptionDate.map((info) => (
                      <option
                        // value={moment(new Date(info.value)).format(
                        //   "YYYY-MM-DD"
                        // )}
                        value = {info.value}
                        key={info.value}
                      >
                        {info.label}
                      </option>
                    ))}
                  </Field>
                  <i className="arrow_down fas fa-chevron-down"></i>
                  <div className="single-error-div">
                    <ErrorMessage
                      name="preferredResumptionDate"
                      render={(msg) => (
                        <p
                          style={{ color: "red", fontSize: "12px" }}
                          className="text-danger font-sm error1 font-weight-bold"
                        >
                          {msg}
                        </p>
                      )}
                    />
                  </div>
                </div>

                <div className="flexme lastbtn">
                  <button
                    type="button"
                    onClick={() => props.prev(values)}
                    className="btn goback"
                  >
                    Go Back
                  </button>
                  <button
                    type="submit"
                    disabled={props.loading}
                    className="btn"
                  >
                    Submit
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

const MyTextArea = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field] = useField(props);
  return (
    <div
      style={{ padding: ".5rem 1rem", margin: "1rem 0", background: "#F6F7F9" }}
    >
      <label className="label" htmlFor={props.id || props.name}>
        {label}
      </label>
      <textarea
        className="text-area"
        {...field}
        {...props}
        style={{
          width: "100%",
          resize: "none",
          background: "#F6F7F9",
        }}
      />
    </div>
  );
};

export default Education;
