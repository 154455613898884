import React, { useState } from "react"
import {Link} from "react-router-dom";
import LandingPageHeader from "../../components/Header";
import { publicFetchWithoutAuthorization } from "../../util/fetch";
import FormError from "../../components/FormError";
import FormSuccess from "../../components/FormSuccess";
import { useFormik } from "formik";
//new updates
import StyleLogin from "./SignUp/styles";
import Footer from "./SignUp/Footer";
// import Swal from "sweetalert2";
//end of new updates
const Login = () => {
  const [loginSuccess, setLoginSuccess] = useState(false);
  const [loginSuccessMsg, setLoginSuccessMsg] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setloading] = useState(false);
  const loginFormFields = useFormik({
    initialValues: { email: "", password: "" },
    validate: (values) => {
      const errors = {};
      const EMAIL_PATTERN =
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const correctEmailAddress = EMAIL_PATTERN.test(
        String(values.email).toLowerCase()
      );
      if (!values.email) {
        errors.email = "Email address is required!";
      }
      if (values.email && !correctEmailAddress) {
        errors.email = "Email address is invalid!";
      }
      if (!values.password) {
        errors.password = "Password is required!";
      }
      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      setloading(true);
      await publicFetchWithoutAuthorization
        .post(`/auth/login`, values)
        .then(({ data }) => {
          setSubmitting(false);
          localStorage.setItem("userInfo", JSON.stringify(data.data));
          window.location.href = "/applicant/info";
          loginFormFields.resetForm();
          setloading(false);
        })
        .catch((error) => {
          const errMsg = error.response.data.error;
          setLoginError(true);
          setLoginErrorMsg(errMsg);
          setTimeout(() => {
            setLoginError(false);
            setLoginErrorMsg("");
          }, 3500);
          setLoginSuccessMsg("");
          setLoginSuccess(false);
          setloading(false);
        });
    },
  });

  // const resendLink = async (value = "") => {
  //   setloading(true);
  //   await publicFetchWithoutAuthorization
  //     .post(`/auth/send-verification`, { email: value })
  //     .then(({ data }) => {
  //       console.log(data);
  //       Swal.fire({
  //         position: "center",
  //         icon: "success",
  //         title: "Verification link sent",
  //         showConfirmButton: false,
  //         timer: 2000,
  //       });
  //       setLoginError(false);
  //       setLoginErrorMsg("");
  //       setloading(false);
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       const errMsg = error.response.data.error;
  //       if (errMsg === null) {
  //         setloading(false);
  //         return;
  //       }
  //       console.log(errMsg);
  //       setLoginError(true);
  //       setLoginErrorMsg(errMsg);
  //       setTimeout(() => {
  //         setLoginError(false);
  //         setLoginErrorMsg("");
  //       }, 3500);
  //       setLoginSuccessMsg("");
  //       setLoginSuccess(false);
  //       setloading(false);
  //     });
  // };

  return (
    <>
      <LandingPageHeader />
      <StyleLogin>
        <h1 className="login-title" style={{ fontWeight: "700" }}>
          Log in to your account
        </h1>
        {loginSuccess && <FormSuccess text={loginSuccessMsg} />}
        {loginError && <FormError text={loginErrorMsg} />}

        <form onSubmit={loginFormFields.handleSubmit}>
          <div className="wrapper double-wrapper-left">
            <div className="label">
              <p>Email address</p>
            </div>
            <input
              name="email"
              type="email"
              placeholder="Type your email"
              className="input-email"
              value={loginFormFields.values.email}
              onChange={loginFormFields.handleChange}
              onBlur={loginFormFields.handleBlur}
              style={{
                width: "100%",
                border:
                  loginFormFields.touched.email &&
                  loginFormFields.errors.email &&
                  "1px solid transparent",
              }}
            />
          </div>

          {loginFormFields.touched.email && loginFormFields.errors.email ? (
            <span className="text-base font-serif small text-red-700">
              {loginFormFields.errors.email}
            </span>
          ) : null}

          <div className="wrapper double-wrapper-left">
            <div className="label">
              <p>Password </p>
            </div>
            <input
              name="password"
              type={`${showPassword ? "type" : "password"}`}
              style={{ width: "90%" }}
              placeholder="Type your password"
              className="input-email"
              value={loginFormFields.values.password}
              onChange={loginFormFields.handleChange}
              onBlur={loginFormFields.handleBlur}
            />
            <i
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"} `}
            ></i>
          </div>
          {loginFormFields.touched.password &&
          loginFormFields.errors.password ? (
            <span className="text-base font-serif small text-red-700">
              {loginFormFields.errors.password}
            </span>
          ) : null}
          <div className="others">
            <label
              className="container"
              style={{ marginTop: ".6rem", visibility: "hidden" }}
            >
              <input type="checkbox" />
              <span className="checkmark"></span>
              Remember me
            </label>
            <Link to="/resendverificationlink"
              // type="button"
              style={{ border: "none", outline: "none" }}
              // onClick={() => resendLink(loginFormFields.values.email)}
              className="resend-verify"
              disabled={!loginFormFields.values.email}
            >
              Resend Verification Link 
            </Link>
          </div>

          <button
            type="submit"
            className="btn login-btn"
            style={{
              marginTop: "1rem",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
            disabled={loading}
          >
            {loading ? <i class="fa fa-spinner fa-spin"></i> : "Log me in"}
          </button>
          <p className="forgot">
            Forgot your password?
            <a href="/forgotpassword" className="forgot-btn inner-link">
              &ensp;Reset it here
            </a>{" "}
          </p>
        </form>

        <Footer />
      </StyleLogin>
    </>
  );
};

export default Login;
