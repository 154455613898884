import React from "react";
// import darkLogo from '../images/logo-dark.png';
// import lightLogo from '../images/decagonLogo.png';
// import './Header.scss'
import { Link } from "react-router-dom";
import styled from "styled-components";
import decagon from "../images/decagon.svg";

const MainHeader = ({ content }) => {
  return (
    <>
      <Style>
        <header class="main-header flex">
          <a href="/login">
            <img src={decagon} alt="decagon-logo" />
          </a>
          <div class="apply-btns">
            <Link to="/" class="btn">
              Apply to become a decadev
            </Link>
          </div>
        </header>
      </Style>
    </>
  );
};

const Style = styled.div`
  .flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }

  .main-header {
    padding: 1rem 5rem;
    border-bottom: 1px solid #ececec;
  }

  .btn {
    color: #fff;
    text-decoration: none;
    background: linear-gradient(135deg, #b8d344 0%, #34a853 100%);
    padding: 1rem 3rem;
  }

  @media only screen and (max-width: 768px) {
    .main-header {
      padding: 1rem 2rem;
    }
    img {
      width: 60%;
    }
    .btn {
      font-size: 0.7rem;
      padding: 1rem;
    }
  }

  @media only screen and (max-width: 450px) {
    .main-header {
      padding: 1rem 2rem;
    }
    img {
      width: 50%;
      padding: 0.2rem;
    }
    .btn {
      font-size: 0.7rem;
      padding: 0.5rem 1rem;
    }
  }
`;

export default MainHeader;
