import React, { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

const AuthContext = createContext();
const { Provider } = AuthContext;

const AuthProvider = ({ children }) => {
  const history = useHistory();
  const userInfo = localStorage.getItem('userInfo');

  const [authState, setAuthState] = useState({
    userInfo: userInfo ? JSON.parse(userInfo) : { token: ''}
  });

  const setAuthInfo = (userInfo) => {
    localStorage.setItem(
      'userInfo',
      JSON.stringify(userInfo)
    );
    setAuthState({
      userInfo,
    });
  };

  const logout = () => {
    if (isAuthenticated() && !isAdmin()) {
      history.push('/');
    }
    localStorage.removeItem('userInfo');
    localStorage.removeItem('assessmentSchedule');
    setAuthState({});
  };

  const isAuthenticated = () => {
    return authState?.userInfo?.token;
  };

  const isTokenValid = () => {
    if(authState?.userInfo?.expiryDate){
      const currentTime = new Date()
      const expirationTime = new Date(authState.userInfo.expiryDate)
      return currentTime < expirationTime;
    }
    return false
  }

  const isAdmin = () => {
    return authState?.userInfo?.role;
  };

  const isApplicantRegistrationComplete = () => {
    return authState?.userInfo?.personalInfo && authState?.userInfo?.addressInfo && authState?.userInfo?.educationInfo && authState?.userInfo?.otherInfo;
  };

  return (
    <Provider
      value={{
        authState,
        setAuthState: authInfo => setAuthInfo(authInfo),
        logout,
        isAuthenticated,
        isAdmin,
        isTokenValid,
        isApplicantRegistrationComplete
      }}
    >
      {children}
    </Provider>
  );
};

export { AuthContext, AuthProvider };
