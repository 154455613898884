import React from "react";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <>
      <p className="login-bottom ">
        Don't have an account?{" "}
        <Link to="/" className="inner-link">
          Get started to Register to become a decadev
        </Link>
      </p>
    </>
  );
};

export default Footer;
