import axios from "axios";

const token = () => {
  const userInfo = localStorage.getItem("userInfo");
  const data = JSON.parse(userInfo) || "";
  return data.token;
};

const publicFetch = axios.create({
  baseURL: process.env.REACT_APP_PROD_BASE_URL,
  headers: { Authorization: `Bearer ${token()}` },
});

const publicFetchWithoutAuthorization = axios.create({
  baseURL: process.env.REACT_APP_PROD_BASE_URL,
});

const usingFetch = async (endpoint, prop) => {
  return axios.get(`${process.env.REACT_APP_PROD_BASE_URL}${endpoint}`, {
    headers: {
      Authorization: `Bearer ${token()}`,
    },
    ...prop,
  });
};

publicFetch.interceptors.request.use(
  (config) => {
    const token = JSON.parse(localStorage.getItem("userInfo")).token;
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export { publicFetch, publicFetchWithoutAuthorization, usingFetch };
