import React, { useState } from "react";
import Header from "../../components/Header";
import Footer from "./SignUp/Footer";
import StyleLogin from "./SignUp/styles";
import { useFormik } from "formik";
import FormError from "../../components/FormError";
import { publicFetchWithoutAuthorization } from "../../util/fetch";
const Forgot = () => {
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMsg, setLoginErrorMsg] = useState("");
  const [loading, setloading] = useState(false);
  const verifyFormFields = useFormik({
    initialValues: { email: "" },
    validate: (values) => {
      const errors = {};
      const EMAIL_PATTERN =
        /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      const correctEmailAddress = EMAIL_PATTERN.test(
        String(values.email).toLowerCase()
      );
      if (!values.email) {
        errors.email = "Email address is required!";
      }
      if (values.email && !correctEmailAddress) {
        errors.email = "Email address is invalid!";
      }
      return errors;
    },
    onSubmit: async (values, { setSubmitting }) => {
      setloading(true);
      await publicFetchWithoutAuthorization
        .post(`/auth/send-verification`, values)
        .then(({ data }) => {
          window.location.href = "/check_verify";
        })
        .catch((error) => {
          console.log(error);
          const errMsg = error.response.data.error;
          if (errMsg === null) {
            setloading(false);
            return;
          }
          console.log(errMsg);
          setLoginError(true);
          setLoginErrorMsg(errMsg);
          setTimeout(() => {
            setLoginError(false);
            setLoginErrorMsg("");
          }, 3500);
          setloading(false);
        });
    },
  });
  return (
    <>
      <Header />
      <StyleLogin>
        <h1 className="login-title">Resend Verification Link?</h1>
        <p
          style={{ width: "35%", textAlign: "center", margin: "1rem 0" }}
          className="forgot-text"
        >
          Enter your email below and we will send you a new verification link to
          verify your email address.
        </p>
        <form onSubmit={verifyFormFields.handleSubmit}>
          {loginError && <FormError text={loginErrorMsg} />}

          <div className="wrapper double-wrapper-left">
            <div className="label">
              <p>Email address</p>
            </div>
            <input
              name="email"
              type="email"
              placeholder="Type your email"
              style={{ width: "100%" }}
              className="input-email"
              value={verifyFormFields.values.email}
              onChange={verifyFormFields.handleChange}
              onBlur={verifyFormFields.handleBlur}
            />
          </div>
          {verifyFormFields.touched.email && verifyFormFields.errors.email ? (
            <span className="text-base font-serif small text-red-700">
              {verifyFormFields.errors.email}
            </span>
          ) : null}

          <button
            type="submit"
            style={{ margin: "2rem 0" }}
            className="btn login-btn"
          >
            {loading ? <i class="fa fa-spinner fa-spin"></i> : "Resend Link"}
          </button>
        </form>
        <Footer />
      </StyleLogin>
    </>
  );
};
export default Forgot;
