import styled from "styled-components";
const StyleLogin = styled.div`
  min-height: 40rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  .login-title {
    opacity: 0.8;
    font-size: 2rem;
    letter-spacing: 2px;
  }
  form {
    width: 22%;
  }

  .flexme {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wrapper {
    position: relative;
    flex-basis: 110%;
    background: rgba(203, 203, 203, 0.2);
    border: 1px solid #ededed;
    box-sizing: border-box;
    padding: 0.3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0.5rem auto;
    margin-bottom: 0 !important;
  }

  .label {
    font-weight: bold;
    font-size: 1rem;
    padding-bottom: 0.5rem;
    color: #2d2f48;
    opacity: 0.7;
  }

  .input-email {
    border: none;
    background: transparent;

    :focus {
      outline: none;
      background: transparent;
      border: none;
    }
    ::placeholder {
      background: transparent;
      color: #666666;
      opacity: 0.8;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px #f5f5f5 inset !important;
  }

  .remember {
    font-size: 12px;
  }

  .btn {
    color: #fff;
    text-align: center;
    text-decoration: none;
    background: linear-gradient(135deg, #b8d344 0%, #34a853 100%);
    padding: 1rem 3rem;
  }

  .login-btn {
    display: block;
    border: none;
    width: 100%;
    margin: 0 auto;
  }

  .login-btn:active {
    border: none;
    outline: none;
  }

  .login-btn:focus {
    outline: none;
  }

  .forgot {
    padding: 0.5rem 0;
    font-weight: normal;
    font-size: 12px;
    color: #2d2f48;
    text-align: center;
  }

  //checkbox
  .container {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 0.7rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 0.5px solid #cecece;
    background-color: #fff;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #fff;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid rgba(0, 0, 0, 0.5);
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .inner-link {
    color: #2d2f48;
    text-decoration: none;
    font-weight: bold;
  }

  .others {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .resend-verify {
    display: block;
    font-size: 0.7rem;
    flex-basis: 70%;
  }

  .login-bottom {
    border-top: 1px solid #e5e5e5;
    font-size: 12px;
    position: fixed;
    bottom: 0rem;
    width: 100%;
    text-align: center;
    padding: 1rem 0;
  }

  .small {
    font-size: 0.7rem;
  }

  //upload
  .upload {
    margin: 1rem 0;
    width: 100%;
    height: 150px;
    border: 1px solid #ededed;
  }
  .upload .drag {
    margin-top: -7rem;

    text-align: center;
    color: #666666;
    font-family: Arial;
    z-index: -100;
  }
  .upload input {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
  }

  textarea::placeholder {
    padding: 0.2rem;
  }

  .lastbtn {
    padding-top: 1rem !important;
  }

  .recom {
    margin-top: 6px;
    font-size: 14px;
    font-weight: 600;
    color: #b93815;
    width: 90%;
    text-align: center;
    background-color: #fef7c3;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  button:disabled {
    background-color: #cccccc !important;
    color: gray;
  }

  @media only screen and (max-width: 768px) {
    .login-title {
      width: 80%;
      margin: 0 auto;
      text-align: center;
    }

    .renewPass {
      width: 70% !important;
    }
    form {
      width: 90%;
    }
    .forgot-text {
      width: 60% !important;
    }
    .flexme {
      /* flex-direction: column;
  width:100%; */
    }
    #check {
      width: 70% !important;
    }
  }
`;

export default StyleLogin;
