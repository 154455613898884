import React from "react";
import decagon from "../../../NewAssets/decagon.svg";
import styled from "styled-components";
import arrowlight from "../../../NewAssets/arrowlight.svg";
import arrowchecked from "../../../NewAssets/arrowchecked.svg";
import grayCircle from "../../../NewAssets/grayCircle.svg";
const Sidebar = ({ currentStep, setCurrentStep }) => {
  return (
    <>
      <div className="sidebar">
        <SidebarStyle>
          <a href="/">
            <img
              src={decagon}
              className="decagon-logo sidebar-img"
              alt="decagon-logo"
            />
          </a>
          <div className="side-tab" id="mobile-flex">
            <p className="start-tab">START APPLICATION</p>
            <button onClick={() => setCurrentStep(0)} className="first-tab tab">
              <img
                src={currentStep > 0 ? arrowchecked : arrowlight}
                className="arrow"
                alt="decagon-logo"
              />{" "}
              <p className="personal-tab">Personal information & Education</p>
            </button>
            <button
              // onClick={() => setCurrentStep(1)}
              className="second-tab tab"
            >
              <img
                src={currentStep > 0 ? arrowlight : grayCircle}
                className="arrow"
                alt="decagon-logo"
              />
              <p className="education-tab">
                {" "}
                Programming Experience, Achievements &<br /> Leadership, Others
              </p>
            </button>
          </div>
        </SidebarStyle>
      </div>
    </>
  );
};

const SidebarStyle = styled.div`
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: flex-start;

  img.decagon-logo {
    margin: 1.2rem 2rem;
    width: 50%;
  }
  .side-tab {
    margin: 5rem 2rem;
  }
  .start-tab {
    font-size: 1rem;
    font-weight: 500;
    text-transform: uppercase;
  }

  .first-tab,
  .second-tab {
    display: flex;
    align-items: center;
  }

  .tab {
    padding: 1rem 0;
    color: #000;
    text-decoration: none;
    outline: none;
  }

  .personal-tab,
  .education-tab {
    padding-left: 1rem;
    text-align: left;
  }

  @media only screen and (max-width: 768px) {
    .sidebar-img {
      display: none;
    }

    #mobile-flex {
      display: flex;
      flex-direction: row !important;
    }

    .side-tab {
      margin: 1rem 0;
    }

    .start-tab {
      display: none;
    }
    .first-tab,
    .second-tab {
      margin-left: 0.5rem;
    }
  }

  @media only screen and (max-width: 450px) {
    .personal-tab,
    .education-tab {
      font-size: 0.8rem !important;
    }
  }
`;

export default Sidebar;
