import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Header from "../../components/Header";
import Footer from "./SignUp/Footer";
import StyleLogin from "./SignUp/styles";
import FormError from "../../components/FormError";
import { useFormik } from "formik";
import { publicFetchWithoutAuthorization } from "../../util/fetch";
import * as Yup from "yup";
import Swal from "sweetalert2";
const Renew = () => {
  const [resetError, setResetError] = useState(false);
  const [resetErrorMsg, setResetErrorMsg] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setloading] = useState(false);
  const location = useLocation();
  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const Schema = Yup.object().shape({
    password: Yup.string().required("This field is required"),
    confirmPassword: Yup.string()
      .required("This field is required")
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Both password need to be the same"
        ),
      }),
  });
  const onSubmit = async (values, onSubmitProps) => {
    const obj = {
      password: formik.values.password,
      confirmPassword: formik.values.confirmPassword,
      token: location.search.split("=")[1],
    };
    setloading(true);
    await publicFetchWithoutAuthorization
      .patch(`/auth/reset-password`, obj)
      .then(({ data }) => {
        setResetError(false);
        setResetErrorMsg("");
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Password changed successfully",
          showConfirmButton: false,
          timer: 3000,
        });
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
        setloading(false);
      })
      .catch((error) => {
        const errMsg = error.response.data.error;
        setResetError(true);
        setResetErrorMsg(errMsg);
        setTimeout(() => {
          setResetError(false);
          setResetErrorMsg("");
        }, 3500);

        setloading(false);
      });
    onSubmitProps.resetForm();
    onSubmitProps.setSubmitting(false);
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validationSchema: Schema,
    validateOnMount: true,
  });

  return (
    <>
      <Header />

      <StyleLogin>
        <h1 className="login-title">Enter your new password</h1>
        <p
          className="renewPass"
          style={{ width: "35%", textAlign: "center", margin: "1rem 0" }}
        >
          Enter your new password to gain access to your account area.
        </p>
        {resetError && <FormError text={resetErrorMsg} />}
        <form onSubmit={formik.handleSubmit}>
          <div className="wrapper double-wrapper-left">
            <div className="label">
              <p>Password </p>
            </div>
            <input
              name="password"
              type={`${showPassword ? "type" : "password"}`}
              style={{ width: "90%" }}
              placeholder="Type your password"
              className="input-email"
              {...formik.getFieldProps("password")}
            />
            <i
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"} `}
            ></i>
          </div>
          {formik.touched.password && formik.errors.password && (
            <p className="text-base font-serif small text-red-700">
              {formik.errors.password}
            </p>
          )}
          <div className="wrapper double-wrapper-left">
            <div className="label">
              <p>Password </p>
            </div>
            <input
              name="password"
              type={`${showPassword ? "type" : "password"}`}
              style={{ width: "90%" }}
              placeholder="Confirm your password"
              className="input-email"
              {...formik.getFieldProps("confirmPassword")}
            />
            <i
              onClick={() => {
                setShowPassword(!showPassword);
              }}
              className={`fas ${showPassword ? "fa-eye-slash" : "fa-eye"} `}
            ></i>
          </div>
          {formik.touched.confirmPassword && formik.errors.confirmPassword && (
            <p className="text-base font-serif small text-red-700">
              {formik.errors.confirmPassword}
            </p>
          )}

          <button
            type="submit"
            style={{ margin: "1.5rem 0" }}
            className="btn login-btn"
          >
            {loading ? <i class="fa fa-spinner fa-spin"></i> : "Reset password"}
          </button>
        </form>
        <Footer />
      </StyleLogin>
    </>
  );
};

export default Renew;
