import React, { lazy, Suspense, useContext } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { AuthContext, AuthProvider } from "./context/AuthContext";
import { FetchProvider } from "./context/FetchContext";
import Home from "./pages/applicant/Home";
import FourOFour from "./pages/FourOFour";
import AdminLogin from "./pages/admin/Login";
import EmailVerification from "./pages/applicant/EmailVerification";
import PrivateRoute, { ProtectedRoute } from "./router";
import NewSignup from "./layout/DashboardLayout";
import Congrats from "./pages/applicant/SignUp/Congrats";
import Forgot from "./pages/applicant/Forgot";
import Check from "./pages/applicant/Check";
import CheckVerify from "./pages/applicant/CheckVerify";
import Reset from "./pages/applicant/Reset";
import ResendVerify from "./pages/applicant/ResendVerify";
import usePageTracking from "./util/usePageTracking";

const Registration = lazy(() => import("./pages/applicant/Dashboard"));
const Details = lazy(() => import("./pages/applicant/Details"));
const AdminSection = lazy(() => import("./pages/admin/Adminsection"));

const UnauthenticatedRoutes = () => {
  usePageTracking()
  const auth = useContext(AuthContext);
  const applicantAuth =
    auth.isAuthenticated() && !auth.isAdmin() && auth.isTokenValid();
  const adminAuth =
    auth.isAuthenticated() && auth.isAdmin() && auth.isTokenValid();
  const registrationAuth =
    auth.isAuthenticated() &&
    auth.isApplicantRegistrationComplete &&
    !auth.isAdmin() &&
    auth.isTokenValid();


  return (
    <Switch>
      <ProtectedRoute
        exact
        path="/login"
        isAuth={applicantAuth}
        ifAuth={"/applicant"}
      >
        <Home />
      </ProtectedRoute>
      <Route exact path="/success">
        <Congrats />
      </Route>
      <Route exact path="/resendverificationlink">
        <ResendVerify />
      </Route>
      <Route exact path="/check_verify">
        <CheckVerify />
      </Route>
      <ProtectedRoute
        exact
        path="/"
        isAuth={applicantAuth}
        ifAuth={"/applicant"}
      >
        <NewSignup />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path="/forgotpassword"
        isAuth={applicantAuth}
        ifAuth={"/applicant"}
      >
        <Forgot />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path="/check"
        isAuth={applicantAuth}
        ifAuth={"/applicant"}
      >
        <Check />
      </ProtectedRoute>

      <ProtectedRoute
        exact
        path="/reset-password"
        isAuth={applicantAuth}
        ifAuth={"/applicant"}
      >
        <Reset />
      </ProtectedRoute>

      <Route exact path="/email-verification">
        <EmailVerification />
      </Route>
      <ProtectedRoute
        exact
        path="/registration"
        isAuth={registrationAuth}
        ifAuth={"/applicant"}
      >
        <Registration />
      </ProtectedRoute>
      <ProtectedRoute
        exact
        path="/admin"
        isAuth={adminAuth}
        ifAuth={"/admin/dashboard"}
      >
        <AdminLogin />
      </ProtectedRoute>
      <ProtectedRoute path="*">
        <FourOFour />
      </ProtectedRoute>
    </Switch>
  );
};

const AppRoutes = () => {
  const auth = useContext(AuthContext);
  const applicantAuth =
    auth.isAuthenticated() && !auth.isAdmin() && auth.isTokenValid();
  const adminAuth =
    auth.isAuthenticated() && auth.isAdmin() && auth.isTokenValid();

  return (
    <>
      <Suspense fallback={"Loading........"}>
        <Switch>
          <PrivateRoute
            path="/admin/dashboard"
            isAuth={adminAuth}
            notAuth={"/admin"}
          >
            <AdminSection />
          </PrivateRoute>
          <PrivateRoute path="/applicant" isAuth={applicantAuth} notAuth={"/"}>
            <Details />
          </PrivateRoute>
          <PrivateRoute
            path="/registration"
            isAuth={applicantAuth}
            notAuth={"/"}
          >
            <Registration />
          </PrivateRoute>
          <UnauthenticatedRoutes />
        </Switch>
      </Suspense>
    </>
  );
};

function App() {
  return (
    <Router>
      <AuthProvider>
        <FetchProvider>
          <div>
            <AppRoutes />
          </div>
        </FetchProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
