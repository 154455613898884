import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { gender, state } from "./data";
// import moment from "moment";
import * as Yup from "yup";
import { publicFetchWithoutAuthorization } from "../../../util/fetch";

// import DateView from "react-datepicker";
// import DatePicker from "react-date-picker";
// import "react-datepicker/dist/react-datepicker.css";
import FormError from "../../../components/FormError";
import Loader from "../../../components/common/Loader";
import { grade, nysc, qualification } from "./data";
import DatePickerDialog from "./DatePicker";
import { parse } from "date-fns";

// const timeDate = moment(new Date("15/03/2023")).format("YYYY-MM-DD");
// const timeDate = moment(new Date("15/03/2023")).format("DD-MM-YYYY");
// console.log(timeDate);

const Personal = (props) => {
  const [allData, setAllData] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const location = useLocation();
  const firstSearch = location.search.split("=")[0];
  let id = location.search.split("=")[1];

  const getInfo = async () => {
    await publicFetchWithoutAuthorization
      .get(`/public/bootcamp/${id}`)
      .then(({ data }) => {
        const obj = {
          ...data.data,
          dateOfBirth: new Date(data.data?.dateOfBirth),
          confirmEmail: data.data?.email,
        };

        const valuesObj = Object.keys(obj).reduce((acc, key) => {
          if (Object.keys(props.data).includes(key)) {
            acc[key] = obj[key];
          }
          return acc;
        }, {});
        setAllData(valuesObj);
        setSignupErrorMsg("");
        setloading(false);
      })
      .catch((error) => {
        const errMsg = error.response.data.error;
        setSignupError(true);
        setSignupErrorMsg(errMsg);
        setloading(false);
      });
  };
  useEffect(() => {
    if (id !== undefined && firstSearch === "?app") {
      getInfo();
    }
    return;
    // eslint-disable-next-line
  }, [id]);

  const validationSchema1 = Yup.object().shape({
    firstName: Yup.string()
      .required("First name is required.")
      .min(3, "First name is too short, must be at least 3 Characters long."),
    lastName: Yup.string()
      .required("Last name is required.")
      .min(3, "Last name is too short, must be at least 3 Characters long."),
    middleName: Yup.string()
      .required("Middle name is required.")
      .notRequired()
      .min(3, "Middle name is too short, must be at least 3 Characters long.")
      .nullable(true),
    email: Yup.string().email().required("Email is required."),
    confirmEmail: Yup.string().test(
      "Emails-match",
      "Both Emails need to be the same",
      function (value = "") {
        if (this.parent.email && value) {
          return this.parent.email.toLowerCase() === value.toLowerCase();
        }
      }
    ),

    gender: Yup.string().required("Gender is required."),
    dateOfBirth: Yup.date()
    .transform(function (value, originalValue) {
      const result = parse(originalValue, "y-MM-dd", new Date());
      return result;
    })
    .typeError("please enter a valid date")
    .required("Date is required"),
    phoneNumber: Yup.string()
      .required("Phone Number is required.")
      .min(10, "Phone number is invalid")
      .nullable(true),
    whatsAppNumber: Yup.string()
      .notRequired()
      .min(10, "WhatsApp Number is invalid")
      .nullable(true),
    stateOfOrigin: Yup.string().required("State of Origin is required."),
    currentLocation: Yup.string().required("Location is required."),
    highestQualification: Yup.string()
      .required("Qualification is required.")
      .nullable(true),
    institution: Yup.string().required("Institution is required.").nullable(),
    courseOfStudy: Yup.string().required("Course is required.").nullable(),
    degree: Yup.string().required("Grade is required.").nullable(),
    nyscStatus: Yup.string().required("NYSC is required.").nullable(),
  });

  const [signupError, setSignupError] = useState(false);
  const [signupErrorMsg, setSignupErrorMsg] = useState("");
  const [loading, setloading] = useState(false);

  const handleSubmit = async (values) => {

    setloading(true);
    const obj = {
      applicationCategory: values?.applicationCategory,
      firstName: values?.firstName,
      lastName: values?.lastName,
      middleName: values?.middleName,
      email: values?.email,
      confirmEmail: values?.confirmEmail,
      gender: values?.gender,
      dateOfBirth: dateOfBirth,
      phoneNumber: values?.phoneNumber,
      whatsAppNumber: values?.whatsAppNumber,
      stateOfOrigin: values?.stateOfOrigin,
      currentLocation: values?.currentLocation,
      highestQualification: values?.highestQualification,
      institution: values?.institution,
      courseOfStudy: values?.courseOfStudy,
      degree: values?.degree,
      nyscStatus: values?.nyscStatus,
    };

    // /bootcamp/personal-info
    await publicFetchWithoutAuthorization
      .post(`/public/bootcamp/personal-info`, obj)
      .then(({ data }) => {
        setSignupErrorMsg("");
        const ID = data.data._id;
        props.next({ ...values, _id: ID });
        setloading(false);
      })
      .catch((error) => {
        const errMsg = error.response.data.error;
        setSignupError(true);
        setSignupErrorMsg(errMsg);
        setloading(false);
      });
  };

  return (
    <>
      {loading && <Loader />}
      <span className="recom">
        Recommended browser: <b>Google Chrome</b>
      </span>
      {/* <p className="mx-6 md:mx-10  my-2 text-red-700 font-bold">
        If your application for our January cohort is successful, you will be
        invited for an assessment this week.
      </p> */}
      <div className="content ">
        <h1 className="tell-us font-bold">Tell us about yourself</h1>
        {signupError && <FormError text={signupErrorMsg} />}
        <Formik
          initialValues={allData || props.data}
          onSubmit={handleSubmit}
          validationSchema={validationSchema1}
          enableReinitialize
        >
          {({ setFieldValue, values }) => {
            return (
              <Form className="personal-info-form">
                <div className="flex">
                  <div className="wrapper double-wrapper-left">
                    <div className="label">
                      <p>What is your First Name?</p>
                    </div>
                    <Field
                      name="firstName"
                      type="text"
                      placeholder="Type your first name"
                      style={{ width: "100%" }}
                      className="input-email"
                    />
                    <div className="single-error-div">
                      <ErrorMessage
                        name="firstName"
                        render={(msg) => (
                          <p
                            style={{ color: "red", fontSize: "12px" }}
                            className="text-danger font-sm error1 font-weight-bold"
                          >
                            {msg}
                          </p>
                        )}
                      />
                    </div>
                  </div>

                  <div className="wrapper double-wrapper-right">
                    <div className="label">
                      <p>What is your last name?</p>
                    </div>
                    <Field
                      name="lastName"
                      type="text"
                      placeholder="Type your last name"
                      style={{ width: "100%" }}
                      className="input-email"
                    />
                    <div className="single-error-div">
                      <ErrorMessage
                        name="lastName"
                        render={(msg) => (
                          <p
                            style={{ color: "red", fontSize: "12px" }}
                            className="text-danger font-sm error1 font-weight-bold"
                          >
                            {msg}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex">
                  <div className="wrapper double-wrapper-left">
                    <div className="label">
                      <p>What is your middle name?</p>
                    </div>
                    <Field
                      name="middleName"
                      type="text"
                      placeholder="Type your middle name"
                      style={{ width: "100%" }}
                      className="input-email"
                    />
                    <div className="single-error-div">
                      <ErrorMessage
                        name="middleName"
                        render={(msg) => (
                          <p
                            style={{ color: "red", fontSize: "12px" }}
                            className="text-danger font-sm error1 font-weight-bold"
                          >
                            {msg}
                          </p>
                        )}
                      />
                    </div>
                  </div>

                  <div className="wrapper double-wrapper-right">
                    <div className="label">
                      <p>What is your email address?</p>
                    </div>
                    <Field
                      name="email"
                      type="email"
                      placeholder="Type your email"
                      style={{ width: "100%" }}
                      className="input-email"
                    />
                    <div className="single-error-div">
                      <ErrorMessage
                        name="email"
                        render={(msg) => (
                          <p
                            style={{ color: "red", fontSize: "12px" }}
                            className="text-danger font-sm error1 font-weight-bold"
                          >
                            {msg}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="wrapper">
                  <div className="label">
                    <p>Confirm your email address?</p>
                  </div>
                  <Field
                    name="confirmEmail"
                    type="confirmEmail"
                    placeholder="Confirm your email"
                    style={{ width: "100%" }}
                    className="input-email"
                  />
                  <div className="single-error-div">
                    <ErrorMessage
                      name="confirmEmail"
                      render={(msg) => (
                        <p
                          style={{ color: "red", fontSize: "12px" }}
                          className="text-danger font-sm error1 font-weight-bold"
                        >
                          {msg}
                        </p>
                      )}
                    />
                  </div>
                </div>

                <div className="flex">
                  <div className="wrapper double-wrapper-left">
                    <div className="label">
                      <p>What is your gender?</p>
                    </div>

                    <Field as="select" name="gender" style={{ width: "90%" }}>
                      <option>Select a gender</option>
                      {gender.map((gender) => (
                        <option value={gender.value} key={gender.value}>
                          {gender.label}
                        </option>
                      ))}
                    </Field>
                    <i className="arrow_down fas fa-chevron-down"></i>
                    <div className="single-error-div">
                      <ErrorMessage
                        name="gender"
                        render={(msg) => (
                          <p
                            style={{ color: "red", fontSize: "12px" }}
                            className="text-danger font-sm error1 font-weight-bold"
                          >
                            {msg}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="wrapper double-wrapper-right">
                    <div className="label">
                      <p>What is your date of birth? </p>
                    </div>
                    
                    {/* <Field name={"dateOfBirth"}>
                      {({ field }) => {
                        const { value } = field;

                        return (
                          <DayPicker
                            id={"dateOfBirth"}
                            {...field}
                            maxDate={new Date("01/01/2005")}
                            placeholderText={"MM/DD/YYYY"}
                            selected={value}
                            onChange={(val) => {
                              setFieldValue("dateOfBirth", val);
                            }}
                          />
                        );
                      }}
                    </Field> */}
                    {/* <Field
                      name="dateOfBirth"
                      type="date"
                      maxDate={new Date("01/01/2005")}
                      placeholderText={"MM/DD/YYYY"}
                      className="input-email"
                    /> */}
                    <DatePickerDialog
                      // label="Date of Birth"
                      name="dateOfBirth"
                      setDateValue={setDateOfBirth}
                      dateValue={dateOfBirth}
                      setFieldValue={setFieldValue}
                    />
                    <div className="single-error-div">
                      <ErrorMessage
                        name="dateOfBirth"
                        render={(msg) => (
                          <p
                            style={{ color: "red", fontSize: "12px" }}
                            className="text-danger font-sm error1 font-weight-bold"
                          >
                            {msg}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                </div>

                <div className="wrapper">
                  <div className="label">
                    <p>What is your phone number?</p>
                  </div>
                  <Field
                    name="phoneNumber"
                    type="number"
                    placeholder="Type your phone number"
                    style={{ width: "100%" }}
                    className="input-email"
                  />
                  <div className="single-error-div">
                    <ErrorMessage
                      name="phoneNumber"
                      render={(msg) => (
                        <p
                          style={{ color: "red", fontSize: "12px" }}
                          className="text-danger font-sm error1 font-weight-bold"
                        >
                          {msg}
                        </p>
                      )}
                    />
                  </div>
                </div>

                <div className="wrapper">
                  <div className="label">
                    <p>What is your WhatsApp number? (Optional)</p>
                  </div>
                  <Field
                    name="whatsAppNumber"
                    type="number"
                    placeholder="Type your WhatsApp number"
                    style={{ width: "100%" }}
                    className="input-email"
                  />
                  <div className="single-error-div">
                    <ErrorMessage
                      name="whatsAppNumber"
                      render={(msg) => (
                        <p
                          style={{ color: "red", fontSize: "12px" }}
                          className="text-danger font-sm error1 font-weight-bold"
                        >
                          {msg}
                        </p>
                      )}
                    />
                  </div>
                </div>

                <div className="wrapper">
                  <div className="label">
                    <p>What is your state of origin?</p>
                  </div>
                  <Field
                    as="select"
                    name="stateOfOrigin"
                    style={{ width: "94%" }}
                  >
                    <option>Select option</option>
                    {state.map((state) => (
                      <option value={state.value} key={state.value}>
                        {state.label}
                      </option>
                    ))}
                  </Field>{" "}
                  <i className="fas arrow_down fa-chevron-down"></i>
                  <div className="single-error-div">
                    <ErrorMessage
                      name="stateOfOrigin"
                      render={(msg) => (
                        <p
                          style={{ color: "red", fontSize: "12px" }}
                          className="text-danger font-sm error1 font-weight-bold"
                        >
                          {msg}
                        </p>
                      )}
                    />
                  </div>
                </div>
                <div className="flex justify-between"></div>

                <div className="wrapper">
                  <div className="label">
                    <p>What is your current location?</p>
                  </div>
                  <Field
                    as="select"
                    name="currentLocation"
                    style={{ width: "95%" }}
                  >
                    <option>Select option</option>
                    {state.map((state) => (
                      <option value={state.value} key={state.value}>
                        {state.label}
                      </option>
                    ))}
                  </Field>
                  <i className="arrow_down fas fa-chevron-down"></i>
                  <div className="single-error-div">
                    <ErrorMessage
                      name="currentLocation"
                      render={(msg) => (
                        <p
                          style={{ color: "red", fontSize: "12px" }}
                          className="text-danger font-sm error1 font-weight-bold"
                        >
                          {msg}
                        </p>
                      )}
                    />
                  </div>
                </div>
                <div className="mt-6">
                  <h1 className="tell-us font-bold">Education</h1>
                  <p className="better">We would like some more information.</p>
                  <div className="wrapper">
                    <div className="label">
                      <p>What is your highest qualification?</p>
                    </div>
                    <Field
                      as="select"
                      name="highestQualification"
                      style={{ width: "95%" }}
                    >
                      <option>Select option</option>
                      {qualification.map((qualification) => (
                        <option
                          value={qualification.value}
                          key={qualification.value}
                        >
                          {qualification.label}
                        </option>
                      ))}
                    </Field>
                    <i className="arrow_down fas fa-chevron-down"></i>

                    <div className="single-error-div">
                      <ErrorMessage
                        name="highestQualification"
                        render={(msg) => (
                          <p
                            style={{ color: "red", fontSize: "12px" }}
                            className="text-danger font-sm error1 font-weight-bold"
                          >
                            {msg}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                  <div className="wrapper">
                    <div className="label">
                      <p>Where did you graduate from?</p>
                    </div>
                    <Field
                      name="institution"
                      type="text"
                      placeholder="Type your School's name"
                      style={{ width: "100%" }}
                      className="input-email"
                    />
                    <div className="single-error-div">
                      <ErrorMessage
                        name="institution"
                        render={(msg) => (
                          <p
                            style={{ color: "red", fontSize: "12px" }}
                            className="text-danger font-sm error1 font-weight-bold"
                          >
                            {msg}
                          </p>
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex">
                    <div className="wrapper double-wrapper-left">
                      <div className="label">
                        <p>Course of Study</p>
                      </div>

                      <Field
                        name="courseOfStudy"
                        type="text"
                        placeholder="Type your Course of Study"
                        style={{ width: "100%" }}
                        className="input-email"
                      />
                      <div className="single-error-div">
                        <ErrorMessage
                          name="courseOfStudy"
                          render={(msg) => (
                            <p
                              style={{ color: "red", fontSize: "12px" }}
                              className="text-danger font-sm error1 font-weight-bold"
                            >
                              {msg}
                            </p>
                          )}
                        />
                      </div>
                    </div>

                    <div className="wrapper double-wrapper-right">
                      <div className="label">
                        <p>Grade Achieved</p>
                      </div>
                      <Field as="select" name="degree" style={{ width: "90%" }}>
                        <option>Select option</option>
                        {grade.map((grade) => (
                          <option value={grade.value} key={grade.value}>
                            {grade.label}
                          </option>
                        ))}
                      </Field>
                      <i className="arrow_down fas fa-chevron-down"></i>
                      <div className="single-error-div">
                        <ErrorMessage
                          name="degree"
                          render={(msg) => (
                            <p
                              style={{ color: "red", fontSize: "12px" }}
                              className="text-danger font-sm error1 font-weight-bold"
                            >
                              {msg}
                            </p>
                          )}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="wrapper ">
                    <div className="label">
                      <p>NYSC Status</p>
                    </div>
                    <Field
                      as="select"
                      name="nyscStatus"
                      style={{ width: "95%" }}
                    >
                      <option>Select option</option>
                      {nysc.map((nysc) => (
                        <option value={nysc.value} key={nysc.value}>
                          {nysc.label}
                        </option>
                      ))}
                    </Field>
                    <i className="arrow_down fas fa-chevron-down"></i>
                    <div className="single-error-div">
                      <ErrorMessage
                        name="nyscStatus"
                        render={(msg) => (
                          <p
                            style={{ color: "red", fontSize: "12px" }}
                            className="text-danger font-sm error1 font-weight-bold"
                          >
                            {msg}
                          </p>
                        )}
                      />
                    </div>
                  </div>
                </div>
                <p
                  style={{
                    color: "#666666",
                    fontSize: "14px",
                    marginTop: "14px",
                  }}
                >
                  By proceeding (if you click Next), you agree to Decagon's
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://decagonhq.com/privacy/"
                    style={{ color: "#34A853" }}
                  >
                    {" "}
                    Privacy Policy
                  </a>
                </p>
                <button
                  disabled={loading}
                  type="submit"
                  className="btn personal-btn"
                >
                  Next
                </button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default Personal;
