import React, { useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import StyleLogin from "../pages/applicant/SignUp/styles";
import Sidebar from "../pages/applicant/SignUp/Sidebar";
import decagon from "../NewAssets/decagon.svg";
import Aside from "../pages/applicant/SignUp/Aside";
import Personal from "../pages/applicant/SignUp/Personal";
import Education from "../pages/applicant/SignUp/Education";
import { AuthContext } from "../context/AuthContext";
import FormError from "../components/FormError";
import FormSuccess from "../components/FormSuccess";
import { publicFetchWithoutAuthorization } from "../util/fetch";
import Loader from "../components/common/Loader";
const Dashboard = () => {
  const location = useLocation();
  const firstSearch = location.search.split("=")[0];
  let category = location.search.split("=")[1];
  if (firstSearch === "?applicant") {
    if (category === "edo_applicants") {
      category = "Edo State";
    } else if (category === "first_class") {
      category = "First Class Graduate";
    } else if (category === "experienced") {
      category = "Ex-Boot Camper";
    } else if (category === "womenInTech") {
      category = "Women in Tech";
    } else {
      category = "General";
    }
  }

  const [data, setData] = useState({
    _id: "",
    applicationCategory: category || "General",
    firstName: "",
    lastName: "",
    middleName: "",
    email: "",
    confirmEmail: "",
    gender: "",
    dateOfBirth: "",
    phoneNumber: "",
    whatsAppNumber: "",
    stateOfOrigin: "",
    currentLocation: "",
    highestQualification: "",
    institution: "",
    courseOfStudy: "",
    degree: "",
    nyscStatus: "",
    programmingExperience: "",
    productExperience: "",
    descriptionOfProjectContribution: "",
    outstandingAchievement: "",
    leadershipPositions: "",
    descriptionOfLeadershipPosition: "",
    paymentOption: "",
    linkedIn: "",
    hearAboutUs: "",
    preferredResumptionDate: "",
  });
  let history = useHistory();
  const authContext = useContext(AuthContext);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [signupSuccessMsg, setSignupSuccessMsg] = useState("");
  const [signupError, setSignupError] = useState(false);
  const [signupErrorMsg, setSignupErrorMsg] = useState("");
  const [loading, setloading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const signUpRequest = async (values) => {
    setloading(true);

    let formData = new FormData();
    // formData.append("application-cv", values.file);

    const valuesObj = Object.keys(values).reduce((acc, key) => {
      if (key !== "file") {
        acc[key] = values[key];
      }
      return acc;
    }, {});

    formData.append(
      "boot-camp-request",
      new Blob([JSON.stringify(valuesObj)], {
        type: "application/json",
      })
    );

    await publicFetchWithoutAuthorization
      .post(`/public/bootcamp`, formData)
      .then(({ data }) => {
        authContext.setAuthState(data);
        setSignupError(false);
        setSignupErrorMsg("");
        history.push("/success");
        setloading(false);
      })
      .catch((error) => {
        const errMsg = error?.response?.data?.error || error?.message;
        setSignupError(true);
        setSignupErrorMsg(errMsg);
        setSignupSuccessMsg("");
        setSignupSuccess(false);
        setloading(false);
      });
  };

  const handleNextStep = (newData, final = false) => {
    setData((prev) => ({ ...prev, ...newData }));
    if (final) {
      signUpRequest(newData);
      return;
    }
    setCurrentStep((prev) => prev + 1);
  };

  const handlePrevStep = (newData) => {
    setData((prev) => ({ ...prev, ...newData }));
    setCurrentStep((prev) => prev - 1);
  };
  const steps = [
    <Personal next={handleNextStep} data={data} />,
    <Education
      loading={loading}
      next={handleNextStep}
      prev={handlePrevStep}
      data={data}
    />,
  ];
  return (
    <>
      {loading && <Loader />}
      <DashboardStyle>
        <Sidebar currentStep={currentStep} setCurrentStep={setCurrentStep} />
        <div className="header">
          <img
            src={decagon}
            className="decagon-logo sidebar-img mobile-img"
            alt="decagon-logo"
          />
          <h2 className="header-title">Get Started</h2>
          <a href="https://decagon.institute/" rel="noreferrer" target="_blank">
            About Program
          </a>
        </div>

        <StyleLogin>
          {signupSuccess || signupError ? (
            <div className="error">
              {signupSuccess && <FormSuccess text={signupSuccessMsg} />}
              {signupError && <FormError text={signupErrorMsg} />}
            </div>
          ) : null}
          {steps[currentStep]}
        </StyleLogin>

        {currentStep === 0 ? (
          <Aside
            text1={"Get started"}
            text2={
              "We would like to know about you this would us understand you better"
            }
          />
        ) : (
          <Aside
            text1={"Education and others"}
            text2={"We would like to know about your educational background."}
          />
        )}
      </DashboardStyle>
    </>
  );
};

const DashboardStyle = styled.div`
  display: grid;
  grid-template-areas:
    "nav header header"
    "nav content aside";
  grid-template-columns: 1fr 2fr 1.3fr;
  .sidebar {
    grid-area: nav;
    background: #fafafa;
  }
  .header {
    grid-area: header;
    padding: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ececec;
  }
  .header-title {
    font-size: 1.2rem;
    display: block;
  }
  .content {
    margin: 2rem 0;
    flex-direction: column !important;
    justify-content: flex-start;
    grid-area: content;
    width: 90%;
  }

  .tell-us {
    text-align: left;
    font-size: 1.5rem;
  }

  .better {
    color: #666666;
    margin: 0.5rem 0;
  }

  .double-wrapper-right,
  .double-wrapper-left {
    flex-basis: 100%;
  }

  .double-wrapper-right {
    margin-left: 1rem;
  }
  .content .personal-info-form {
    width: 100%;
  }

  .aside {
    grid-area: aside;
    border-left: 1px solid #e5e5e5;
  }

  input,
  select {
    width: 100%;
    font-size: 15px;
    color: #666666;
    background: none;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    option {
      background: #2d2f48;
    }
  }
  .wrapper select {
    width: 100% !important;
  }

  input:focus,
  select:focus {
    border: none;
    outline: none;
  }
  .flex2 {
    margin: 2rem 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .aside-content {
    padding: 2rem;
    background: rgba(177, 209, 69, 0.1);
  }

  .getstart {
    margin-left: 1rem;
  }

  .getcontent {
    width: 60%;
    font-size: 0.9rem;
  }

  .personal-btn {
    margin-top: 1rem;
    border: none;
    float: right;
    width: 40%;
  }

  .lastbtn {
    justify-content: flex-end !important;
    align-items: flex-end;
  }

  .lastbtn .btn {
    display: block;
    border: none;
  }

  .goback {
    font-size: 0.8rem !important;
    background: #fff;
    color: #b1d145;
    border: 1px solid #b1d145 !important;
    margin-right: 1rem;
  }

  .error {
    padding: 2rem;
  }

  .mobile-img {
    display: none !important;
  }

  textarea:focus {
    outline: none;
    border: none;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  .react-date-picker {
    width: 100% !important;
  }
  .react-date-picker__wrapper {
    border: none !important;
  }

  .arrow_down {
    position: absolute;
    z-index: -99999;
    pointer-events: none;
    right: 1rem;
  }
  @media only screen and (max-width: 768px) {
    display: grid;
    grid-template-areas:
      "header header"
      "nav nav"
      "content content";
    grid-template-columns: 2fr;
    grid-template-rows: 0.1fr 0.1fr 2fr;

    .aside {
      display: none;
    }

    .header {
      border-bottom: 2px solid #ececec;
    }

    .mobile-img {
      display: block !important;
    }

    .header-title {
      display: none;
    }

    .content {
      margin: 2rem 0;
    }

    .flex {
      display: block;
    }

    .double-wrapper-right {
      margin-left: 0rem;
    }

    .lastbtn {
      display: flex;
    }
  }
`;

export default Dashboard;
